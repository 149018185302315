<template>
  <div></div>
</template>

<script>
export default {
  beforeMount() {
    window.location.replace('https://checkleaked.cc/apk/CheckLeaked.apk');
  },
};
</script>